/* General styles */
body {
  overscroll-behavior: contain;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-attachment: fixed;
}

.leaderboard-container {
  text-align: center;
  padding: 10px;
  color: white;
  overflow: hidden;
  max-width: 100vw;
}

.podium-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;
}

.podium {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  position: relative;
  width: 300px;
  max-width: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
}

.podium-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
}

.podium-step::before {
  content: '';
  position: absolute;
  bottom: -15px;
  width: 100%;
  height: 15px;
  background-color: #ccc;
  border-radius: 2px 2px 0 0;
  z-index: 1;
}

.podium-1 .podium-step::before {
  background-color: silver;
  height: 55px;
}

.podium-2 .podium-step::before {
  background-color: gold;
  height: 80px;
}

.podium-3 .podium-step::before {
  background-color: #cd7f32;
  height: 30px;
}

.podium-1 .image-container {
  transform: translateY(20px); /* Adjust position above podium */
}

.podium-2 .image-container {
  transform: translateY(-8px); /* Adjust position above podium */
}

.podium-3 .image-container {
  transform: translateY(40px); /* Adjust position above podium */
}

.podium-step .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
  margin-bottom: 50px;
}

.h2 {
  left: 10px;
}

/* Podium Animation Class */
.animate-podium {
  animation: podiumAppear 1s forwards;
}

@keyframes podiumAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.podium-image,
.meme-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.elo-score {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3px 8px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
}

/* Podium Elo Score centered */
.elo-score-podium {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1px 8px;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  white-space: nowrap;
  z-index: 2;
}

/* Center podium scores on each specific podium step based on their height */
.podium-1 .elo-score-podium {
  top: 92%;
}

.podium-2 .elo-score-podium {
  top: 87%;
}

.podium-3 .elo-score-podium {
  top: 95.39%;
}

/* Meme Grid */
.meme-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 100vw;
}

.meme-item {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.meme-item .image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Pagination */
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

.pagination button {
  margin: 3px;
  padding: 0.4rem 0.8rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Meme Preview (Modal) */
.meme-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer; /* Makes the blur area clickable */
}

.meme-preview-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(35px);
  z-index: -1;
  pointer-events: none; /* Prevent clicks from affecting the blurred area */
}

.meme-preview-content {
  display: flex;
  flex-direction: column; /* Stack meme and score vertically */
  align-items: center; /* Center align */
  justify-content: center;
  max-width: 90vw; /* Limit width to 90% of the viewport */
  max-height: 90vh; /* Limit height to 90% of the viewport */
  padding: 0;
  overflow: hidden; /* Prevent overflow */
}

/* Meme Image */
.preview-meme {
  max-width: 100%; /* Allow the meme to scale down */
  max-height: 80vh; /* Set max height to 80% of viewport height */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the entire image fits without cropping */
}

/* Elo Score */
.preview-elo-score {
  margin-top: 20px; /* Space between the meme and the score */
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px;
  white-space: nowrap;
  text-align: center;
}

/* Prevent body scrolling when preview is open */
.no-scroll {
  overflow: hidden;
  height: 100vh;
}

.pagination button.active {
  background-color: #0056b3; /* Darker color for active page */
  color: #fff;
  font-weight: bold;
  transform: scale(1.1);
}

@media (min-width: 767px) {
  .leaderboard-container {
    padding-top: 30px;
  }
}


/* Mobile Adjustments */
@media (max-width: 767px) {
  .podium-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
  }

  .podium {
    width: 30%;
    max-width: 100px;
    margin: 5px;
  }

  .podium-step .image-container {
    width: 100%;
    height: auto;
    position: relative;
  }

  .meme-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .pagination button {
    padding: 0.3rem 0.6rem;
    font-size: 0.8rem;
  }

  .meme-preview-content {
    width: auto; /* Change width to auto to accommodate the image's aspect ratio */
    height: auto; /* Ensure height adjusts automatically */
    max-width: 90vw; /* Keep a limit on width */
    max-height: 90vh; /* Keep a limit on height */
  }

  .preview-meme {
    max-width: 90vw; /* Limit width to 90% of the viewport width */
    max-height: 80vh; /* Ensure it fits in the mobile view */
    object-fit: contain; /* Maintain aspect ratio without cropping */
  }

  .elo-score-podium {
    margin-top: -30px;
    font-size: 1.0rem;
    white-space: normal; /* Allow text to wrap */
  }
  

  .preview-elo-score {
    margin-top: 15px;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
    text-align: center;
    white-space: normal;
  }

  /* Prevent body scrolling when preview is open */
  .no-scroll {
    overflow: hidden;
    height: 100vh;
  }

  .elo-score {
    z-index: 1;
  }
}

@media (min-width: 768px) {
  /* Meme hover effect */
  .meme-item:hover {
    transform: scale(1.05); /* Slightly scale up the meme */
    transition: transform 0.3s ease; /* Smooth transition */
    cursor: pointer; /* Change cursor to indicate it's clickable */
  }

  /* Podium meme hover effect */
  .podium:hover .podium-image {
    transform: scale(1.05); /* Slightly scale up the podium image */
    transition: transform 0.3s ease; /* Smooth transition */
    cursor: pointer; /* Change cursor to indicate it's clickable */
  }

  /* Pagination button hover effect */
  .pagination button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    color: #ffffff; /* Keep text white */
    transform: scale(1.1); /* Slightly scale up */
    transition: transform 0.2s ease, background-color 0.2s ease; /* Smooth transitions */
  }
}
