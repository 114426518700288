.avatar-container {
  position: relative;
  display: inline-block;
}

.current-avatar {
  width: 80px; /* Slightly bigger for better visibility */
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  margin-right: 10px;
}

.avatar-wheel {
  position: fixed; /* Position fixed to cover the entire screen */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's on top of other content */
  pointer-events: all; /* Allow interactions with the avatars */
}

.avatar-option {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute; /* Position avatars absolutely within the wheel */
}

.avatar-option:hover {
  transform: scale(1.2); /* Slightly enlarge on hover */
  opacity: 1;
}

@media (max-width: 768px) {
.avatar-wheel {
    width: 100vw;
    height: 100vh;
}
}
