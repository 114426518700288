/* App.css */
* {
  box-sizing: border-box;
}

/* Set body to cover the whole viewport */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-attachment: fixed;
  background: linear-gradient(to bottom right, #6a11cb, #2575fc);
}

/* Background styles */
.app-background {
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom right, #6a11cb, #2575fc);
  overflow-x: hidden;
}

.app-header {
  width: 100vw;
  margin-bottom: 20px;
}

.app-title {
  margin-block-start: 0;
  margin-block-end: 0;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
}

.user-avatar {
  width: min(50vw, 50vh); /* Avatar will scale based on the smaller of width or height */
  height: min(50vw, 50vh); /* Ensures the aspect ratio remains 1:1 */
  border-radius: 50%; /* Keep it a circle */
  margin: 20px 0;
  border: 3px solid #ff7e5f;
}

.parent-container {
  text-align: center; /* Center the text inside the container */
  width: 100%; /* Ensure the container takes full width */
}

.gradient-text {
  color: white;
  font-weight: bold;
  text-shadow: -5px 5px 4px #00000080;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  display: inline; /* Keep text in a single line */
}

.welcome-message {
  text-align: center;
}

/* Side menu styles */
.side-menu {
  width: 250px; /* Default width of the menu */
  height: 100%;
  background-color: #333;
  color: white;
  transition: width 0.3s;
  position: relative;
  overflow: hidden; /* Prevent content overflow */
}

/* Menu items */
.menu-item {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #444; /* Separating lines */
  text-align: center; /* Center text */
}

/* Hover effect on menu items */
.menu-item:hover {
  background-color: #444; /* Change background on hover */
}

/* Main content area */
.main-content {
  margin-top: 40px;
  flex: 1; /* Allow the main content to take remaining space */
  color: white; /* Default text color */
}