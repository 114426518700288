/* Base Styles (common) */
.game-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.matchup {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.meme-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 25vw;
    max-width: 800px;
    max-height: 800px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
}

.meme-box img,
.meme-box video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 20px;
    border-color: aquamarine
}

.vote-wrapper {
    z-index: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.meme-box p {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0.5rem;
    margin: 0;
    border-radius: 0 0 10px 10px;
    z-index: 1;
}

.vote-button,
.confirm-button {
    padding: 0.625rem 1.25rem;
    background-color: #4285F4;
    color: white;
    font-weight: bold;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 7px 7px 4px 0px #00000040;
}

.vote-divider {
    z-index: 0;
}

.vote-button:hover {
    background-color: #0056b3;
}

.confirm-button:hover {
    background-color: #0056b3;
}

.elo-up {
    color: green;
    margin-left: 0.625rem;
    font-size: 0.875rem;
}

.elo-down {
    color: red;
    margin-left: 0.625rem;
    font-size: 0.875rem;
}

/* Desktop Styles */
@media (min-width: 768px) {
    .desktop-matchup {
        display: flex;
        justify-content: space-around;
        width: 80%;
        gap: 40px;
    }

    .meme-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: (35vw, 35vh); /* Set width to 35vw */
        height: (35vw, 35vh); /* Set height to 35vw */
    }

    .meme-box {
        width: 100%;
        max-width: 35vw;
        max-height: 80vh;
    }

    .vote-button {
        margin: 10px;
        width: 100%;
    }

    .confirm-button {
        width: 100%;
    }

    .meme-preview-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1000;
        padding: 20px;
    }

    .meme-preview-content {
        width: 70%;
        max-height: 90vh;
        border-radius: 10px;
        overflow: hidden;
    }
}

/* Mobile Styles (unchanged) */
@media (max-width: 768px) {
    .game-container {
        padding: 10px;
    }

    .matchup {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .meme-box {
        width: 80vw;
        max-width: 330px;
        max-height: 330px;
    }

    .meme-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vote-container {
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
    }

    .vote-divider {
        z-index: 0;
        margin: 20px;
    }

    .vote-wrapper {
        margin: 10px;
    }

    .meme-box p {
        font-size: 0.875rem;
        padding: 0.25rem;
    }

    .vote-button,
    .confirm-button {
        width: 80vw;  /* Same width as the meme box */
        max-width: 40vw;  /* Match the meme box width */
    }
}
