/* Container for menu and toggle icon */
.menu-container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    z-index: 2;
}

/* Toggle icon styles */
.toggle-icon {
    font-size: 30px;
    color: white;
    padding: 15px;
    cursor: pointer;
    background-color: #333;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1001;
}

/* Side menu styles */
.side-menu {
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.side-menu.closed {
    z-index: 0;
    transform: translateX(100%); /* Slide off the screen */
    transition: transform 0.4s ease, opacity 0.4s ease; /* Smooth slide in/out */
    opacity: 0; /* Hidden */
}

/* When menu is open */
.side-menu.open {
    z-index: 1;
    transform: translateX(0); /* Fully visible */
    transition: transform 0.4s ease, opacity 0.4s ease; /* Smooth slide in/out */
    opacity: 1; /* Fully visible */
}

/* Menu items */
.menu-item {
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #444;
    text-align: center;
}

.submenu-item {
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #444;
    text-align: center;
    background-color: #222;
}

.menu-item:hover, .submenu-item:hover {
    background-color: #444;
}

/* User and auth sections, centered content */
.auth-section, .user-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: auto;
    padding: 20px;
    border-top: 1px solid #444;
    height: 100%; /* Full height for vertical centering */
}

/* User avatar and info */
.user-avatar, .current-avatar {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    cursor: pointer;
    margin-bottom: 10px;
    border: 2px solid #fff;
    z-index: 100;
}

.user-info p {
    margin: 0;
    color: white;
}

/* Full-screen overlay for avatar selector */
.avatar-selector-modal, .avatar-wheel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-option {
    width: 14vw;
    height: 14vw;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
    position: absolute;
}

.avatar-option:hover {
    transform: scale(1.2);
}

/* Button styles */
.auth-section button, .user-section button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #4285F4;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.auth-section button:hover, .user-section button:hover {
    background-color: #357ae8;
}

/* Disable hover effect on mobile devices */
@media (max-width: 768px) {
    .menu-item:hover {
        background-color: transparent;
    }

    .auth-section button:hover,
    .user-section button:hover {
        background-color: #4285F4;
    }

    .avatar-option {
        width: 11vh;
        height: 11vh;
    }
}
